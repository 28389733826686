import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import { AuthProvider } from "./context/AuthProvider";
import { Wrapper } from "@googlemaps/react-wrapper";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DATASOURCE_NAME,
  environment: process.env.REACT_APP_SENTRY_ENV,
  enabled: ["staging", "production"].includes(process.env.REACT_APP_SENTRY_ENV),
});

ReactDOM.render(
  <Router>
    <AuthProvider>
      <ScrollToTop />
      <Wrapper apiKey={process.env.REACT_APP_GMAP_KEY}>
        <App />
      </Wrapper>
    </AuthProvider>
  </Router>,
  document.getElementById("root")
);
